import React, { useEffect, useRef } from 'react'
import LWCLayout from '../layouts/LWCLayout'
import { Helmet } from 'react-helmet'
import OpenPositions from '../components/OpenPositions'
import Quotes from '../components/Quotes'
import I18n from '../utils/I18n'

const CareerLWCTemplate = data => {
  const {pageContext: {item, lang}} = data
  const i18n = new I18n({lang})

  const {
    slug,
    breadcrumb_title,
    section_order,
    sections
  } = item

  const breadcrumbRef = useRef()

  const breadcrumbsItems = [
    {
      href: `/${lang}/career-path`,
      label: i18n.text('breadcrumb_base_title'),
      overflow: false,
      width: 1
    },
    {
      href: '',
      label: breadcrumb_title,
      overflow: false,
      width: 1
    }
  ]

  let promoHtml = <></>;
  if(section_order.indexOf('promo') >= 0 && Object.hasOwnProperty.call(sections, 'promo') ){
    const promo = sections.promo;
    promoHtml =             
                <tm-promo-c 
                    title={promo.title}
                    description={promo.description}
                    img-alt={promo.img_alt}
                    img-src={promo.img_src}
                    primary-cta-href={promo.primary_cta_href} 
                    primary-cta-title={promo.primary_cta_title}
                    primary-cta-target="true" 
                />
  }

  useEffect(() => {
    customElements.whenDefined("tds-breadcrumbs").then(() => {
      breadcrumbRef.current.items = breadcrumbsItems
    })
  }, [])

  return (
    <LWCLayout lang={lang}>
      <Helmet>
        <title>{item.meta_title}</title>
        <meta name="title" content={item.meta_title} />
        <meta name="description" content={item.meta_description}></meta>
        <meta property="og:title" content={item.meta_social_title || item.meta_title} />
        <meta property="og:description" content={item.meta_social_description || item.meta_description} />
        <meta property="og:url" content={`https://trailhead.salesforce.com/career-path/${slug}`} />
        {item.meta_social_image && <meta property="og:image" content={item.meta_social_image} />}
        <meta property="twitter:title" content={item.meta_social_title || item.meta_title} />
        <meta property="twitter:description" content={item.meta_social_description || item.meta_description} />
        <meta property="twitter:url" content={`https://trailhead.salesforce.com/career-path/${slug}`} />
      </Helmet>
      
      <div>
        <tm-page class="theme--careers">
        
          <tds-breadcrumbs ref={breadcrumbRef}></tds-breadcrumbs>
            
          {section_order.length > 0 && section_order.map((sectionName, index) => {
            if(Object.hasOwnProperty.call(sections, sectionName)) {
              
              // HERO
              if (sectionName === 'hero') {
                const hero = sections.hero
                const target = hero.cta_target ? hero.cta_target : null

                return (
                  <tm-hero-a 
                    key={`section-${index}`}
                    background={hero.background}
                    cta-href={hero.cta_href} 
                    cta-label={hero.cta_label} 
                    cta-target={target}
                    img-alt={hero.image_alt} 
                    img-src={hero.image_source} 
                    img-align={hero.image_align}
                    subtitle={hero.subtitle} 
                    title={hero.title}>
                  </tm-hero-a>
                )
              
              } else if (sectionName === 'whatIs') {
                const whatIs = sections.whatIs
                const target = whatIs.cta_target ? whatIs.cta_target : null

                return (
                  <tm-section-a 
                    key={`section-${index}`} 
                    background={whatIs.background} 
                    title={whatIs.title} 
                    description={whatIs.description}
                    cta-title={whatIs.cta_label}
                    cta-href={whatIs.cta_href}
                    cta-target={target} />
                )
              
              } else if (sectionName === 'stats') {
                const stats = sections.stats

                return (
                  <tm-section-a key={`section-${index}`} background={stats.background}>
                    <tm-stats-a>
                      {stats && stats.items.length > 0 && stats.items.map((stat, i) => {
                        return (
                          <tm-stat-item key={`stat-${i}`} description={stat.description} title={stat.title}></tm-stat-item>
                        );
                      })}
                    </tm-stats-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'sounds_like_you') {
                const soundsLikeYou = sections.sounds_like_you

                return (
                  <tm-section-a key={`section-${index}`} background="white" title={i18n.text('sounds_like_you_title')}>
                    <tm-text-item-grid-a icon-type="left">
                      {soundsLikeYou && soundsLikeYou.length > 0 && soundsLikeYou.map((item, i) => {
                        return (
                          <tm-grid-item key={`soundsLikeYou-${i}`} description={item.description} icon-symbol={item.icon} title={item.title}></tm-grid-item>
                        );
                      })}
                    </tm-text-item-grid-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'skills') {
                const skills = sections.skills

                return (
                  <tm-section-a key={`section-${index}`} background="white" title={i18n.text('skills_title')}>
                    <tm-skills-grid-a>
                        <tm-skills-card-a description={skills.general_description} title={skills.general_title}>
                          {skills.general_items && skills.general_items.length > 0 && skills.general_items.map((item, i) => {
                            return (
                              <tm-skill-item key={`generalItem-${i}`} title={item.title} value={item.value}></tm-skill-item>
                            );
                          })}
                        </tm-skills-card-a>
                        <tm-skills-card-a description={skills.specialized_description} theme="warm" title={skills.specialized_title}>
                          {skills.specialized_items && skills.specialized_items.length > 0 && skills.specialized_items.map((item, i) => {
                            return (
                              <tm-skill-item key={`generalItem-${i}`} title={item.title} value={item.value}></tm-skill-item>
                            );
                          })}
                        </tm-skills-card-a>
                    </tm-skills-grid-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'learn') {
                const learn = sections.learn
                const target = learn.cta_target ? learn.cta_target : null
                
                return (
                  <tm-section-a 
                    id="learnSection"
                    key={`section-${index}`}
                    background="light" 
                    cta-href={learn.cta_href} 
                    cta-target={target} 
                    cta-title={learn.title} 
                    description={learn.description}
                    title={learn.title}>
                    
                    <tds-content-tile 
                      data-color={learn.trailmix.color}
                      data-image={learn.trailmix.image}
                      data-path={learn.trailmix.path}
                      data-title={learn.trailmix.title}
                      data-description={learn.trailmix.description}
                      data-type="trailmix"
                      data-estimatedTime={learn.trailmix.estimatedTime}
                      data-points={learn.trailmix.points}
                    ></tds-content-tile>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'why_learn') {
                const why_learn = sections.why_learn

                return (
                  <tm-section-a 
                    key={`section-${index}`} 
                    background={why_learn.background} 
                    title={i18n.text('why_learn_title')}>

                    <tm-text-item-grid-a icon-type="left">
                      {why_learn && why_learn.items.length > 0 && why_learn.items.map((item, i) => {
                        return (
                          <tm-grid-item key={`whyLearn-${index}-${i}`} description={item.description} icon-symbol={item.icon} title={item.title}></tm-grid-item>
                        );
                      })}
                    </tm-text-item-grid-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'in_demand') {
                const inDemand = sections.in_demand

                return (
                  <tm-section-a 
                    key={`section-${index}`}
                    background="white" 
                    cta-href={inDemand.cta_href}
                    cta-target="_blank" 
                    cta-title={inDemand.cta_title}
                    title={inDemand.title}
                  >
                    <tm-jobs-a>
                      {inDemand.jobs && inDemand.jobs.length > 0 && inDemand.jobs.map((job, i) => {
                        return (
                          <tm-job-item 
                            key={`tm-job-item_${index}-${i}`}
                            company={job.company}
                            img-src={job.img_src}
                            location={job.location}
                            position={job.position}
                            salary={job.salary}
                            type={job.type}
                          ></tm-job-item>
                        );
                      })}
                    </tm-jobs-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'map_your_path' && Object.keys(sections.map_your_path).length > 0) {
                const map_your_path = sections.map_your_path

                return (
                  <tm-section-a key={`section-${index}`} background="light">
                    <tm-feature-grid-a>
                        <tm-grid-item 
                          title={map_your_path.title}
                          description={map_your_path.description} 
                          img-src={map_your_path.img_src} 
                          img-alt={map_your_path.img_alt} 
                        ></tm-grid-item>
                    </tm-feature-grid-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'get_inspired' && Object.keys(sections.get_inspired).length > 0) {
                const get_inspired = sections.get_inspired

                return (
                  <tm-section-a 
                    key={`section-${index}`} 
                    background={get_inspired.background} 
                    title={get_inspired.title}>

                    <tm-card-grid-a img-aspect="60%" img-type="full">
                      {get_inspired.items.map((item, i) => {
                        return (
                          <tm-grid-item 
                            key={`gridItem-${index}-${i}`} 
                            title={item.title}
                            description={item.description}
                            cta-href={item.cta_href} 
                            cta-target="true" 
                            cta-title={item.cta_title}
                            img-alt={item.img_alt ? item.img_alt : `${item.title} Image`} 
                            img-src={item.img_src}
                            ></tm-grid-item>
                        )
                      })}
                    </tm-card-grid-a>
                  </tm-section-a>
                )

              
              } else if (sectionName === 'find_your_role' && Object.keys(sections.find_your_role).length > 0) {
                const find_your_role = sections.find_your_role
                let items = find_your_role.roles
                let containerSizeClass = 'slds-container_large'

                if(find_your_role.items_per_row && find_your_role.items_per_row === 4) {
                  containerSizeClass = 'slds-container_x-large'
                }

                return (
                  <tm-section-a
                    key={`section-${index}`}
                    background={find_your_role.background}
                    title={find_your_role.title}
                    description={find_your_role.description}>
                      <div className={`slds-container_center ${containerSizeClass}`}>
                        <tm-card-grid-a img-aspect="60%" img-type="left" class="slds-m-bottom_large">
                          {items.map((role, i) => {
                            const target = role.cta_target ? role.cta_target : null
                            return (
                              <tm-grid-item 
                                key={`findYourRole-${index}-${i}`}
                                title={role.title}
                                description={role.description} 
                                cta-href={role.cta_href}
                                cta-target={target}
                                cta-title={role.cta_title}
                                img-alt={role.img_alt}
                                img-src={role.img_src}
                              />
                            )
                          })}
                        </tm-card-grid-a>
                      </div>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'find_your_role_by_person' && Object.keys(sections.find_your_role_by_person).length > 0) {
                const find_your_role_by_person = sections.find_your_role_by_person

                return (
                  <tm-section-a
                    key={`section-${index}`} 
                    title={find_your_role_by_person.title}
                    description={find_your_role_by_person.description} 
                    background={find_your_role_by_person.background}>

                    {find_your_role_by_person.roles.map((role, index) => {
                      return (
                        <tm-section-a key={`findYourRoleByPerson-${index}`} title={role.title}>
                          <tm-card-grid-a img-aspect="60%" img-type="left">
                            {role.items.map((roleItem, i) => {
                              return (
                                <tm-grid-item 
                                  key={`findYourRole-${index}-${i}`}
                                  title={roleItem.title}
                                  description={roleItem.description} 
                                  cta-href={roleItem.cta_href}
                                  cta-target="true" 
                                  cta-title={i18n.text('cta_take_the_trail')}
                                  img-alt={roleItem.img_alt}
                                  img-src={roleItem.img_src}
                                />
                              )
                            })}
                          </tm-card-grid-a>
                        </tm-section-a>
                      )
                    })}
                  </tm-section-a>
                )
              
              } else if (sectionName === 'quotes' && Object.keys(sections.quotes).length > 0) {
                const quotes = sections.quotes;
                
                return (
                  <Quotes key={`section-${index}`} background={quotes.background} quotes={quotes}/>
                )

              } else if (sectionName === 'featureGrid' && sections.featureGrid.length > 0) {
                const featureGrid = sections.featureGrid

                return (
                  <tm-section-a key={`section-${index}`}>
                    <tm-feature-grid-a>
                      {featureGrid.map((featureItem, i) => {
                        const target = featureItem.cta_primary_target ? featureItem.cta_primary_target : null

                        return (
                          <tm-grid-item 
                            key={`tm-grid-item_${index}-${i}`}
                            cta-primary-href={featureItem.cta_primary_href} 
                            cta-primary-target={target}
                            cta-primary-title={featureItem.cta_primary_title} 
                            description={featureItem.description}
                            img-alt={featureItem.img_alt} 
                            img-src={featureItem.img_src}
                            title={featureItem.title} 
                          />
                        )
                      })}
                    </tm-feature-grid-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'top_influencers' && Object.keys(sections.top_influencers).length > 0) {
                const top_influencers = sections.top_influencers
                top_influencers.background = top_influencers.background ? top_influencers.background : "";

                return (
                  <tm-section-a key={`section-${index}`} background={top_influencers.background}>
                    <tm-feature-grid-a>
                      <tm-grid-item 
                        cta-primary-href={top_influencers.cta_primary_href} 
                        cta-primary-target="true" 
                        cta-primary-title={top_influencers.cta_primary_title} 
                        description={top_influencers.description}
                        img-alt={top_influencers.img_alt} 
                        img-src={top_influencers.img_src}
                        title={top_influencers.title}
                      />
                    </tm-feature-grid-a>
                  </tm-section-a>
                )
              
              } 
              else if (sectionName === 'newsletter' && Object.keys(sections.newsletter).length > 0) {
                const newsletter = sections.newsletter

                return (
                  <tm-section-a key={`section-${index}`} background={newsletter.background}>
                    {promoHtml}
                    <tm-promo-c 
                      title={newsletter.title}
                      description={newsletter.description}
                      img-alt={newsletter.img_alt}
                      img-src={newsletter.img_src}
                      primary-cta-href={newsletter.primary_cta_href} 
                      primary-cta-title={newsletter.primary_cta_title}
                      primary-cta-target="true" 
                    />
                  </tm-section-a>
                )
              
              } else if (sectionName === 'open_positions_role' && sections.open_positions_role !== '') {
                const open_positions_role = sections.open_positions_role
                
                if (slug === "designer") {
                  return (
                    <div key={`section-${index}`} id="jobs-section">
                      <tm-section-a title={i18n.text('open_positions_title')}>
                        <tm-jobs-a>
                          <tm-job-item 
                            company="IBM"
                            location="100% remote"
                            position="Senior Salesforce UX Designer"
                            type="Full-Time"
                            cta-title={i18n.text('open_positions_cta')}
                            cta-href="https://careers.ibm.com/job/14029842/senior-salesforce-ux-designer-100-remote-remote/?codes=Trailhead"
                            cta-target="true"
                          ></tm-job-item>
                          <tm-job-item 
                            company="PwC"
                            location="Multiple Cities"
                            position="UX Designer-Salesforce-Senior Associate"
                            type="Full time"
                            cta-title={i18n.text('open_positions_cta')}
                            cta-href="https://jobs.us.pwc.com/job/-/-/932/23767629664"
                            cta-target="true"
                          ></tm-job-item>
                          <tm-job-item 
                            company="Accenture"
                            location="Washington, DC"
                            position="Federal - UI/UX Interaction Designer"
                            type="Full time"
                            cta-title={i18n.text('open_positions_cta')}
                            cta-href="https://www.accenture.com/us-en/careers/jobdetails?id=R00017849_en&title=Federal+%e2%80%93+UI%2fUX+Interaction+Designer"
                            cta-target="true"
                        ></tm-job-item>
                          <tm-job-item 
                            company="Slalom"
                            location="Denver"
                            position="Experience Strategy (Salesforce)"
                            type="Full time"
                            cta-title={i18n.text('open_positions_cta')}
                            cta-href="https://jobs.slalom.com//#/post/a0h1R00000BqH53QAF"
                            cta-target="true"
                          ></tm-job-item>
                        </tm-jobs-a>
                      </tm-section-a>
                    </div>
                  )
                  
                } else {
                  return (
                    <OpenPositions key={`section-${index}`} role={open_positions_role} lang={lang} />
                  )
                }
              
              } else if (sectionName === 'explore_resources' && Object.keys(sections.explore_resources).length > 0) {
                const explore_resources = sections.explore_resources
                
                return (
                  <tm-section-a
                    key={`section-${index}`}
                    background={explore_resources.background}
                    title={explore_resources.title}>
                      <tm-card-grid-a img-aspect="60%" img-type="full">
                        {explore_resources.items.map((item, i) => {
                          const target = item.cta_target ? item.cta_target : null
                          return (
                            <tm-grid-item 
                              key={`quote-${index}-${i}`}
                              cta-href={item.cta_href}
                              cta-target={target} 
                              cta-title={item.cta_title}
                              description={item.description}
                              img-alt={`${item.img_alt}`}
                              img-src={item.img_src} 
                              title={item.title} 
                            />
                          )
                        })}
                    </tm-card-grid-a>
                  </tm-section-a>
                )

              } else if (sectionName === 'role_inspiration' && Object.keys(sections.role_inspiration).length > 0) {
                const role_inspiration = sections.role_inspiration

                return (
                  <tm-section-a 
                    key={`section-${index}`} 
                    background={role_inspiration.background} 
                    title={role_inspiration.title}>

                    <tm-card-grid-a img-aspect="60%" img-type="full">
                      {role_inspiration.items.map((item, i) => {
                        const target = item.cta_target ? item.cta_target : null
                        return (
                          <tm-grid-item 
                            key={`gridItem-${index}-${i}`} 
                            title={item.title}
                            description={item.description}
                            cta-href={item.cta_href} 
                            cta-target={target}
                            cta-title={item.cta_title}
                            img-alt={item.img_alt ? item.img_alt : `${item.title} Image`} 
                            img-src={item.img_src}
                            ></tm-grid-item>
                        )
                      })}
                    </tm-card-grid-a>
                  </tm-section-a>
                )
              
              } else if (sectionName === 'end_cap' && Object.keys(sections.end_cap).length > 0) {
                const end_cap = sections.end_cap
                const target = end_cap.cta_target ? end_cap.cta_target : null
                
                return (
                  <tm-end-cap-a
                    key={`section-${index}`} 
                    background="dark" 
                    title={end_cap.title} 
                    subtitle={end_cap.subtitle}
                    cta-href={end_cap.cta_href}
                    cta-label={end_cap.cta_label}
                    cta-target={target}
                    img-alt={`${end_cap.image_alt}`}
                    img-src={end_cap.img_src} 
                  />
                )
              
              
              } else if (sectionName === 'footer' && Object.keys(sections.footer).length > 0) {
                const footer = sections.footer
                
                return (
                  <tm-section-a key={`section-${index}`}>
                    <tm-footnote>
                      <div dangerouslySetInnerHTML={{ __html: footer }}></div>
                    </tm-footnote>
                  </tm-section-a>
                )
              }

            }
          })}
        </tm-page>
        <th-element-loader element="tm-page" style={{height: "100vh"}}></th-element-loader>
      </div>
    </LWCLayout>
  )
}

export default CareerLWCTemplate