import React from 'react';

const Quotes = ({ background, quotes }) => {

    const groups = [];
    let groupItems = [];
    const itemsPerBlock = quotes.items_per_block && quotes.items_per_block > 0 ? quotes.items_per_block : 3;

    for(let i=0; i < quotes.items.length; i++) {
        if(i> 0 && i % itemsPerBlock === 0) {
            groups.push(groupItems);
            groupItems = [];
        }

        groupItems.push(quotes.items[i]);
    }

    groups.push(groupItems);

    return (
        <tm-section-a 
            background={background}
            title={quotes.title} 
            description={quotes.description} >
            
            {groups.map((group, index) => {
                return (
                    <tm-quotes-a key={`group-${index}`} class="carousel">
                        {group.map((item, index2) => {
                            return (
                                <tm-quote
                                    key={`quote-${index2}`}
                                    class="carousel-cell"
                                    author={item.author}
                                    author-link={item.author_link}
                                    img-alt={item.img_alt}
                                    img-src={item.img_src}
                                    role={item.role} 
                                    text={item.text} 
                                />
                            )
                        })}
                    </tm-quotes-a>
                )
            })}
        </tm-section-a>
    );
};

export default Quotes;
