import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';

class OpenPositions extends React.Component {
    constructor(props) {
      super(props);

      const { role, lang, background } = this.props;
      this.role = role;
      this.i18n = new I18n({lang});
      this.background = background ? background : "";

      //this.hideMethod = hideMethod.bind(this);

      // this.state = {
      //   display: display,
      //   startShowAnim: false
      // };
    }

    componentDidMount() {
      this.renderJobsComponent();
    }

    renderJobsComponent = () => {
      window.intervalCheckDscJobsComponent = setInterval(() => {
        if(typeof dscJobsComponent !== 'undefined' && document.getElementById('jobs-content')) {
          clearInterval(window.intervalCheckDscJobsComponent);
          const jobsComponent = new dscJobsComponent('#jobs-content', {
            site: 'careerpath',
            role: this.role,
            whenNoRecords: function() {
              const jobsSection = document.getElementById('jobs-section');
              if(jobsSection) {
                jobsSection.setAttribute('style', 'display: none');
              }
              
            },
            whenFilledRecords: function() {
              const jobsLoadingSpinner = document.getElementById('jobsLoadingSpinner');
              if(jobsLoadingSpinner) {
                jobsLoadingSpinner.remove();
              }
            }
          });
    
        }
      }, 1000);
    };

    render() {

        return (
          <div id="jobs-section">
            <tm-section-a title={this.i18n.text('open_positions_title')} background={this.background}>
              <center id="jobsLoadingSpinner">
                <tds-spinner style={{display: "inline"}}></tds-spinner>
              </center>
              <div data-role={this.role} id="jobs-content">&nbsp;</div>
            </tm-section-a>
          </div>
        );
    };
}
  
export default OpenPositions;